
import React from "react"
import Layout from "../components/layout"

export default () =>
<Layout>
  <div id="content">
  <h1>Contact</h1>

  Andere Beschaeftigungen:<br/>

  Kinderbuchautorin, und Dichterin:<br/>
  <a href="http://www.blurb.com/user/eriynali">Bei Blurb.com</a>
  <br/><br/>
  Photografin:<br/>
  <a href="http://www.flickr.com/photos/eriynali">Bei Flickr.com</a>
  <br/><br/>
  Vegetarisch, Vegan und Roh Feinkost:<br/>
  <img src="images/PK779873.JPG" alt="salad" width="400"/><br/>
  <img src="images/IRMA4088.JPG" alt="cookie" width="400"/><br/>
  <img src="images/1-IMGP1709.JPG" alt="jar of applesauce" width="400"/><br/>
  <br/><br/>
  Wandern mit Familie:<br/>
  <img src="images/7-TKR_2037.JPG" alt="hiking by lake" width="400"/><br/>
  Bild von Tara Roberts.
  </div>
  </Layout>
